import { styled } from '@mui/material/styles';

const BankList = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: `${theme.spacing(3)} 3%`,
  gridTemplateColumns: 'repeat(7, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(4)
  }
}));

const BankLogo = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  height: '50px',
  width: '100%',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 0'
}));

interface GalleryProps {
  bankLogoUrls: string[];
}

export const Gallery = ({ bankLogoUrls }: GalleryProps) => {
  return (
    <BankList>
      {bankLogoUrls.map((bankLogoUrl) => (
        <BankLogo role='img' key={bankLogoUrl} backgroundImage={bankLogoUrl} />
      ))}
    </BankList>
  );
}
